.key-moments-wrapper-v2 {
  /* display: flex; */
  width: 100%;
  height: calc(100%);
  padding: 1rem;
  overflow-y: scroll;
}

.key-moment-title-v2 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 18px;
  cursor: pointer;
}

.key-moment-title-v2-new {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 18px;
  cursor: pointer;
}

.key-moment-time-v2 {
  background-color: #2a2fa1;
  padding: 2px 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  border-radius: 5px;
  font-size: 14px;

  font-style: bold;
}

.moment-wrapper-v2{
  margin-bottom: 1rem;
}

.key-moment-content-div{
  margin-left: 18px;
}