.summary-wrapper{
  display: flex;
  width: 100%;
}

.summary-summary-wrapper{
  height: calc(100%);
  padding: 1rem;
  overflow-y: scroll;

  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.touchpoint{
  margin-bottom: 1rem;
}

.summary-tasks-container{
  height: calc(100%);
  padding: 1rem;
  overflow-y: scroll;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.blue-button{
  padding: 8px 16px;
  background-color: #2A2FA1;
  color: white;
  border-radius: 12px;
  cursor: pointer
  ;
  transition: all 400ms;
  font-size: 14px;
}

.blue-button:hover{
  background-color: #4f53a1;

}

.summary-add-steps-container{
  margin-left: 28px;
}

.modal-root{
  height: 100%;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-div{
  margin: auto auto;
  height: 420px;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 18px 18px;
  border-radius: 12px;
}
.modal-topbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #ccc 1px;
  padding-bottom: 12px;
}
.modal-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}

.summary-input{
  border:solid red 1px;
}

.pink-button-big{
  padding: 18px 12px;
  background-color: #F42D77;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  font-weight: 500;
  color: white;
  transition: all 400ms;
  cursor: pointer;
}

.pink-button-big:hover{
  background-color: #fc6aa0;
}