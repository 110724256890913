.dashboard-wrapper {
  /* background-color: aqua; */
  overflow-y: scroll;
  height: calc(100vh - 5.2rem);
  padding: 1rem;
  padding-bottom: 0;
  /* max-width: 1600px; */
  background: #f2f2f2;
}

.header-wrapper {
  display: flex;
  max-height: 22rem;
  /* max-height: 16rem; */
  gap: 1.5rem;
  /* padding: 1rem 0rem; */
  margin-bottom: 1rem;
  /* box-shadow: -2px 2px 2px 2px rgba(193, 193, 193, 0.25); */
  /* border: 3px solid rgba(0, 0, 0, 0.028); */
  border-radius: 0.75rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.5);
}

.header-greet {
  width: 34%;
  background: #ffffff;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  /* padding: 1rem; */
  /* max-height: 100px; */
  max-height: 150px;
  padding: 2rem 1rem;
}

.header-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2rem 1rem;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
  border-radius: 10px;
  flex: 1 1 auto;
}

.heading-details-heading {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #787878;
}

.heading-details-value {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */
  margin-top: 0.5rem;
  color: #333333;
}

.heading-greet-heading {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  height: 120%;
  overflow-y: scroll;

  color: #333333;
}

.greet-image {
  height: 100%;
}

.highlight-wrapper {
  padding: 1rem;
  border-radius: 10px;
  border: 3px solid rgba(0, 0, 0, 0.028);
}

.highlight {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 4px 4px 4px rgba(210, 210, 210, 0.25);
  border-radius: 10px;
}

.highlight-header {
  display: flex;
  border-radius: 10px 10px 0px 0px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
}

.highlight-image {
  height: 20vh;
  max-height: 100px;
  padding-right: 2rem;
}

.highlight-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #333333;
}

.highlight-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #333333;
}

.highlight-suggestion {
  background: rgba(255, 238, 245, 0.5);
  padding: 1rem;
}

.highlight-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.highlight-suggestion-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 0;
  color: #333333;
}

.highlight-suggestion-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #333333;
}

.highlight-h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #333333;
  margin-top: 0.5rem;
}

.highlight-item {
  display: flex;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.highlight-item:hover {
  background-color: #e1e1e13c;
  border-radius: 5px;
}

.highlight-item-image {
  height: 10vh;
  max-height: 100px;
  padding-right: 1rem;
}

.highlight-list {
  padding: 1rem;
}

.highlight-item-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #333333;
}

.highlight-item-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #9dacb4;
}

.morning-quotes {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #333333;
}

.dashboard-loader-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
