.dashboard-metrics-card {
  flex: 1 1 auto;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1 rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
  height: 100%;
}

.dashboard-metric-card-value {
  text-align: center;
  font-family: Roboto;
  font-size: 3rem;
  font-weight: 400;
}

.dashboard-metric-card-title {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 500;
  max-width: 10rem;
}
