.profile-wrapper {
  display: flex;
  padding: 1rem;
}

.settings-profile-picture {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.settings-profile-content-wrapper {
  margin-left: 2rem;
}

.settings-profile-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.01em;

  color: #333333;
  margin: 0;
  margin-top: 0.5rem;
}

.settings-profile-email {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.01em;

  color: #333333;
}

.settings-profile-password-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.01em;

  color: #333333;
  margin-top: 2rem;
}
