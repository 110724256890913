.semi-donut-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.semi-donut-chart {
  /* height: 100%; */
  /* display: flex; */
}



.chart-bottom {
  /* color: #1278ff;
  line-height: 16px; */
  font-size: 12px;
  margin: 0;
  color: #49484f;
  text-align: center;
  /* font-size: 0.75rem; */
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
}
