.dashboard-metrics-wrapper {
  flex: 1;
  display: flex;
  gap: 1.25rem;
}
.dashboard-two-metrics-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
}
