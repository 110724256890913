.dashboard-highlights-wrapper {
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}
.dashboard-highlights-heading {
  color: #333;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
}

.dashboard-highlights-content {
  flex: 1 1 auto;
  display: flex;
  gap: 1rem;
}
