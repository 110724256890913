.topbar {
  width: 100%;
  display: flex;
  padding: 0rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-logo {
  height: 1.75rem;
}

.topbar-items {
  display: flex;
  gap: 1.25rem;
  align-self: center;
}
.topbar-coins-wrapper {
  border-radius: 0.5rem;
  background: #f5f5ff;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  align-self: center;
  align-items: center;
}

.topbar-coins-wrapper > img {
  width: 0.75rem;
  height: 0.75rem;
  object-fit: contain;
  object-position: center;
}

.topbar-coin-amount {
  color: #f42d77;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
}

.circle-image-small-div{
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;

}

.circle-image-small{
  /* width: 64px;
  height: 64px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

}

.topbar-bell-wrapper {
  align-self: center;
  border-radius: 0.625rem;
  background: #f5f5ff;
  padding: 0.375rem;
}

.topbar-bell-wrapper img {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  object-position: center;
}

.topbar-profile-wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  gap: 12px;
}

.topbar-profile-photo {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center;
  margin-right: 0.35rem;
}

.topbar-profile-photo img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center;
}
