.input-blocks {
  margin-left: 5px;
  width: 500px;
}

.manual-upload-details {
  margin-top: 3rem;
}

/* .member-analysis-heading {
  width: 100px;
  display: inline-block;
} */

.manual-upload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.manual-upload-extra-files-uploader-container {
  margin-top: 50px;
}
.manual-upload-extra-files-uploader--container-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #333333;
}

.manual-upload-analysis-level-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
