.analytics-per-peropn-wrapper {
  margin-top: 1rem;
  margin-left: 1rem;
  width: calc(100% - 232px);
  max-width: 1500px;
}

.person-analytics-header-wrapper {
  display: flex;
  /* justify-content: space-between; */
  max-height: 170px;
  padding: 1rem 0rem;
  /* box-shadow: -2px 2px 2px 2px rgba(193, 193, 193, 0.25); */
  /* border: 3px solid rgba(0, 0, 0, 0.028); */
  border-radius: 10px;
  width: 100%;
}

.margin-right-2 {
  margin-right: 3rem;
}

.person-analysis-highlight-image {
  height: 45px;
}

.analytics-scroll-wrapper {
  height: calc(100vh - 10rem);
  overflow-y: scroll;
}

.meeting-content-wrapper {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
}

.meeting-detailed-analysis {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.category-bubble-green {
  /* background-color: rgb(102, 192, 110); */
  background-color: #e8e8ff;
  /* color: white; */
  font-weight: bold;
  padding: 12px 8px;
  border-radius: 16px;
}
.category-bubble-yellow {
  /* background-color: rgb(197, 191, 104); */
  background-color: #ebf6ff;
  /* color: white; */
  padding: 12px 8px;
  border-radius: 16px;
  font-weight: bold;
}
.category-bubble-red {
  /* background-color: rgb(199, 121, 107); */
  background-color: #ffeef5;
  /* color: white; */
  padding: 12px 8px;
  border-radius: 16px;
  font-weight: bold;
}

.video-modal-highlights {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 8rem;
}

.video-modal-highlights .dashboard-video-modal-thumbnail {
  width: 10rem;
}
