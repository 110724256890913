.center-left-heading {
  display: flex;
  align-items: center;
  /* width: 500px; */
}

.meeting-main-content {
  display: flex;
  /* height: 80vh; */
  height: calc(100vh - 9rem);
  /* overflow-y: scroll; */
}

.member-list-names-list-meeting{
  /* height: calc(100vh - 9rem); */
  width: 100%;
  overflow-y: scroll;
  border-right: solid 4px #eee;
}

.member-list-meeting{
  width: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  overflow-y: scroll;
}