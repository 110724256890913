.dashboard-graph-wrapper {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
}

.dashboard-graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-graph-body {
  flex: 1 1 auto;
  align-self: center;
  width: 100%;
}
