.aiinsight-aiinsight-wrapper {
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;

  border-radius: 12px;
  border: solid 1px #eee;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.aiinsight-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.circle-heading-div {
  /* width: 6rem;
  height: 8rem; */
  /* border-radius: 100%; */
  /* background-color: rgb(187, 216, 230); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #575757;
  font-weight: bold;
  font-size: 24px;
  /* margin-left: auto; */

  margin-top: 0.5rem;

  width: 100%;
}

.heading-text {
  font-weight: 500;
  font-size: 18px;
  color: #575757;
}

.heading-text-small {
  font-weight: 500;
  font-size: 16px;
  color: #575757;
}

/* .info-text {
  font-size: 14px;
  color: #575757;
} */

.aiinsight-assessment-wrapper {
  height: calc(100%);
  /* padding: 1rem; */
  overflow-y: scroll;

  border-radius: 12px;
  border: solid 1px #eee;
  width: 24%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  /* justify-content: center; */
  align-items: center;
}
.assement-top-panel {
  height: 60%;
  width: 100%;
  /* border-radius: 12px;s */
  /* border: solid 1px #333; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assement-bottom-panel {
  height: 40%;
  /* padding: 8px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 6px;
  overflow-y: scroll;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 0rem;
}

.radar-chart {
  width: 100%;
}
.assessment-radar {
  /* background-color: red; */
}

.ai-insight-list {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.ai-list-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(73, 72, 79, 1);
}


.ai-insight-points-container{
  height: 100%;
  /* background-color: red; */
  overflow-y: scroll;
}

.aiinsight-aiinsight-wrapper-scrolless {
  height: 100%;
  padding: 1rem;
  /* overflow-y: scroll; */

  border-radius: 12px;
  border: solid 1px #eee;
  width: 22%;
  display: flex;
  flex-direction: column;
}