.analysis-wrapper {
  /* overflow-y: scroll; */
  /* flex: 1 1 auto; */
  /* flex: 1 6; */
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
  /* max-height: 80vh; */
  /* overflow-x: scroll; */
}

.member-analysis-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* identical to box height */

  color: #333333;
}

.analysis-content {
  /* flex: 1 1 auto; */
  /* overflow-y: overlay; */
  /* overflow-y: scroll; */
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */

  max-height: calc(100vh - 9rem);
}

.analysis-list-wrapper {
  border-right: 2px solid #eeeeee;
}

.member-list {
  /* height: calc(100vh - 10rem); */
  /* background-color: aqua; */
  width: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-top: 1rem; */
  /* padding-right: 1rem; */
  /* overflow-y: overlay; */

  height: calc(100% - 6.5rem);
  overflow-y: scroll;
  
  /* border-right: solid 2px #575757; */
}

.member-list-names-list {
  height: calc(100vh - 9rem);
  width: 100%;
  overflow-y: scroll;
}

.analysis-content {
  display: flex;
}

.user-image {
  height: 1.8rem;
  margin: 0.5rem;
}

.member-list-member {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.2rem 0rem;
  border-radius: 5px;
  cursor: pointer;
}

.member-list-name {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.01em;

  color: #979797;
  padding: 0.5rem 0rem;

  word-wrap: break-word;
}

.member-list-item.Mui-selected .member-list-name {
  color: #2a2fa1;
}

.member-name-arrow {
  color: #2a2fa1;
}

.person-analytics-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: -0.01em;

  color: #272727;
}


.profile-pic-small-div{
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.user-list-spacing{
  margin-right: 18px;
}


