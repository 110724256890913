.settings-container {
  background-color: rgba(242, 242, 242, 1);
}
.settings-wrapper {
  height: calc(100vh - 5.4rem);
  padding: 1rem;
}

/* .member-list {
  height: calc(100vh - 10rem);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #eeeeee;
  margin-top: 1rem;
  padding-right: 1rem;
} */

.settings-list {
  height: calc(100vh - 7rem);
  /* background-color: aqua; */
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #eeeeee;
  margin-top: 1rem;
  padding-right: 1rem;
}

.settings-other-wrapper {
  padding: 1rem;
}

.settings-logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.settings-logo-main {
  height: 2rem;
}

.settings-logo-text {
  height: 1rem;
  margin-left: 0.5rem;
}

.settings-subheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 56px;
  /* or 400% */

  display: flex;
  align-items: center;

  color: #272727;
  margin: 0;
}

.setting-details {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* or 400% */

  display: flex;
  align-items: center;

  color: #787878;
  margin: 0;
}

.setting-heading {
  margin: 0;
  margin-left: 0.5rem;
  font-size: 20px;
}

.setting-scroll {
  height: calc(100vh - 6.4rem);
  overflow-y: scroll;
}

.benchmark-wrapper {
  display: flex;
}

.margin-left-10 {
  margin-left: 10rem;
}

