.meeting-analysis-new-root {
  /* height: 74vh; */
  /* background-color: red; */
  /* width: 76vw; */
  /* width: 100%; */
  /* display: flex; */
  width: calc(100% - 232px);
  /* flex-direction: column;
  justify-content: center; */
  /* margin-top: 80px; */

  /* overflow-y: scroll; */

  padding: 1rem;
  padding-bottom: 0px;

  display: flex;
  flex-direction: column;
}

.meeting-analysis-top-row {
  display: flex;
  flex-direction: row;
  /* background-color: green; */
  justify-content: space-between;

  height: 30%;
}

.meeting-analysis-video-box {
  /* flex: 1; */
  width: 40%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting-analysis-main-chart {
  display: flex;
  flex-direction: column;
  border: solid 1px #eee;
  border-radius: 12px;
  /* flex: 1; */
  width: 55%;
  /* padding: 12px; */
  align-items: center;

  padding: 1rem;

  /* background-color: teal; */
}

.meeting-analysis-bottom-box {
  display: flex;
  /* flex: 3; */
  /* background-color: gainsboro; */
  flex-direction: column;

  /* height: 100%; */
  /* gap: 12px; */

  height: 70%;
}

.meeting-analysis-tabs-row {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
}

.meeting-analysis-tab {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 18px;
  cursor: pointer;

  color: rgba(51, 51, 51, 1);
}
.meeting-analysis-tab-selected {
  background-color: rgba(244, 245, 255, 1);
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 18px;
  cursor: pointer;

  color: rgba(42, 47, 161, 1);
}

.meeting-analysis-content {
  /* display: flex; */
  border-radius: 12px;
  border: solid 1px #eee;
  /* height: calc(100vh - 360px - 2rem);  */
  width: 100%;
  /* height: 42vh; */
  /* height: 90%; */
  /* max-height: 200px; */
  /* padding: 24px; */
  /* overflow-y: scroll; */
  display: flex;
  height: calc(100% - 4.5rem);

  /* flex-grow: 1; */
}

.meeting-analysis-content-borderless {
  width: 100%;
  display: flex;
  height: calc(100% - 4.5rem);
}

.overall-score-chart-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* gap: 6px; */
  height: 100%;
  max-height: 360px;
}

.bold-number-text {
  font-size: 22px;
  font-weight: bold;
  font-family: monospace;
}

.line-chart-engagement {
  /* width: 100%; */
  /* margin-top: 12px; */
  /* width: 400px; */
  /* height: 60%; */

  /* display: flex; */
}

.analysis-chart-v2 {
  /* display: flex; */
}

.chart-heading {
  /* color: #1278ff;
  line-height: 16px;
  font-size: 16px; */
  margin: 0;

  color: #333;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ok-donut-chart {
  height: calc(100% - 16px - 16px);
}

.ok-line-chart {
  margin-top: 1rem;
  height: calc(100% - 16px);
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.legend {
  /* font-family: Source Sans Pro; */
  font-size: 12px;
  font-weight: 400;
  /* line-height: 20px; */
  /* letter-spacing: 0em; */
  /* text-align: left; */
}

.annotions-popup {
  padding: 1rem;
}

.pop-head {
  color: #333;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
}


