.dashboard-higlights-list-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(210, 210, 210, 0.25);
}

.dashboard-highlights-list-header {
  display: flex;
  height: 12rem;
  gap: 0.75rem;
  padding: 1.5rem;
}

.dashboard-highlights-list-header-data {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}
.dashboard-highlights-list-header-heading {
  color: #333;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 600;
}

.dashboard-highlights-list-header-value {
  color: #333;
  font-family: Roboto;
  font-size: 2.5rem;
  font-weight: 400;
}
.dashboard-highlights-list-header-body {
  color: #333;
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 400;
}

.dashboard-highlights-videos-list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1rem;
}

.dashboard-higlights-video-wrapper {
  flex: 1;
  display: flex;
  gap: 0.875rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.dashboard-highlights-video-thumbnail {
  width: 7rem;
  height: 5rem;
}

.dashboard-video-information {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
}

.dashboard-video-information-title {
  color: #333;
  font-weight: 600;
}

.dashboard-video-information-time {
  color: #9dacb4;
  font-weight: 400;
}
