.file-list-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.file-list-component-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}

.file-list-component-delete-button:hover {
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: red;
}
