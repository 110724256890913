.meeting-header-wrapper {
  display: flex;
  align-items: center;
}

.meeting-transcript-section-wrapper {
  width: 50%;
  height: calc(100vh - 10rem);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
  z-index: 100;
}

.meeting-content-iinside-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #282828;
}

.meeting-scroll-wrapper {
  height: calc(100vh - 10rem);
  overflow-y: scroll;
}

.metric {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(210, 210, 210, 0.25);
  border-radius: 10px;
  /* border: 2px solid rgba(233, 233, 233, 0.38); */
  padding: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #333333;
  cursor: pointer;
}

.metric.good {
  border-left: 20px solid #c0e3ff;
}

.metric.bad {
  border-left: 20px solid #ffcde2;
}

.metric.active {
  background: #969aff;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #ffffff;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.transcript-photo {
  height: 2rem;
}

.transcript-wrapper {
  display: flex;
  margin-top: 1rem;
}

.transcript-text {
  margin-left: 1rem;
  /* margin-right: 1rem; */
  width: calc(100% - 5rem);
}

.transcript-user {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #333333;
  margin: 0;
}

.transcript-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #979797;
  margin: 0;
}

.transcript-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #272727;

  margin: 0;
  margin-top: 5px;
}

.transcript-comment {
  background: #f5f5f5;
  box-shadow: 4px 4px 4px rgba(210, 210, 210, 0.25);
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #272727;

  margin: 0;
  margin-top: 5px;
  padding: 1rem;
}

.three-dots {
  height: 2rem;
  width: 1rem;
  cursor: pointer;
}

.percent {
  width: 100%;
  text-align: center;
  margin-top: -4rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 18px;
  /* or 45% */

  color: #49484f;
}

.bottom-percent {
  width: 60%;
  margin-left: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  color: #49484f;
}

.button {
  border: none;
  background-color: white !important;
}

.button:focus {
  border: none;
  outline: none;
}

.button:focus {
  outline: none !important;
}

.popup-wrapper {
  background: #f5f5f5;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
  border-radius: 10px;

  width: 30rem;
  padding: 1rem;
}

.popup-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #787878;
}

.popup-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #333333;
}

.bottom-popup-box {
  background: #ffffff;
  box-shadow: 4px 4px 6px 2px rgba(193, 193, 193, 0.25);
  border-radius: 0px 0px 10px 10px;
  margin: -1rem;
  padding: 1rem;
  margin-top: 1rem;
}

.feedback-test {
  /* width: 50%; */
  word-wrap: break-word;
}

.meeting-video-section-wrapper {
  width: 50%;
  margin-right: 1rem;
}
