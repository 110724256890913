.transcript-wrapper-v2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.transcript-transcript-wrapper-v2 {
  height: calc(100%);
  padding: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* border: 2px #ccc solid;
  border-radius: 18px; */
  /* background-color: blue; */
  gap: 6px;

  border: solid 1px #eee;
  border-radius: 12px;
}

.width70 {
  width: 69%;
}

.width30 {
  width: 30%;
}

.transcript-block {
  cursor: pointer;
}

.transcript-block:hover {
  background-color: rgba(255, 255, 0, 0.485);
}

.transcript-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  cursor: pointer;
}

.transcript-leged {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.transcript-legend-title {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  margin-left: 5px;
}
