.app {
  display: flex;
  max-width: 1800px;
  height: 100vh;
  width: 100%;
}

.sidebar {
  /* border-right: 1px solid #ccc; */
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .topbar {
  display: flex;

  padding: 1rem;
  justify-content: space-between;
  width: calc(100% - 32px);
} */

.iby-logo {
  padding: 1rem;
  padding-bottom: 2rem;
}

/* .topbar-logo {
  height: 1.2rem;
} */

.sidebar-tab {
  width: 140px;
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem;
  padding: 10px;
  color: #979797;
}

.sidebar-tab.active {
  background-color: #f4f5ff;
  color: #2a2fa1;
  border-radius: 5px;
}

.sidebar-icon {
  margin-right: 10px;
}

.view {
  border: 1px solid #2a2fa1;
}

.view-red {
  border: 1px solid #a12a2a;
}
.main-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: overlay;
}

/* .topbar-points {
  display: flex;
  align-items: center;
  background-color: #f5f5ff;
  border-radius: 10px;
  padding: 0.5rem;
  margin: -5rem 0.5rem;
}

.topbar-profile {
  display: flex;
  align-items: center;
}

.topbar-points-score {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: #f42d77;
  margin-left: 5px;
}

.topbar-profile-name {
  margin: -5rem 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  letter-spacing: -0.01em;

  color: #333333;
}

.topbar-profile-image {
  height: 2rem;
  margin: -5rem 0.5rem;
} */

.dropdown-titles {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  color: #333333;
  padding-right: 1rem;
}


*::-webkit-scrollbar {
  width: 10px;
}

/**************************  Auth Page Left Side styling **********************/

.auth-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}

.auth-promotion {
  width: 60%;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-promotion-app-logo {
  width: 35%;
  margin-bottom: 30px;
}

.auth-promotion-auth-page {
  width: 35%;
  z-index: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
}

.auth-promotion-auth-page span {
  color: #4d47c3;
  cursor: pointer;
}

.auth-promotion-logo {
  margin-top: -50px;
  width: 45%;
  z-index: 0;
}

.auth-form-verify-org {
  width: 40%;
  flex-direction: column;
  padding-right: 200px;
}

.auth-form-verify-org-field-wrapper {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.auth-form-verify-org-field-wrapper div {
  width: 100%;
  display: flex;
}

.auth-form-verify-alert {
  margin-bottom: 20px;
}

.auth-form-verify-org-field-wrapper .amplfiy-alert {
  flex-direction: column;
}

/**************************  Auth AWS Form styling **********************/
.amplify-tabs {
  display: none !important;
}

:root {
  /* --amplify-font-sizes-large: 1.25rem;
  --amplify-font-sizes-medium: 1.5rem !important;
  --amplify-font-sizes-small: 1.25rem !important; */

  --amplify-colors-brand-primary-100: #f80a62 !important;
  --amplify-colors-brand-primary-90: #f51e6d !important;
  --amplify-colors-brand-primary-80: #f42d77 !important;
  --amplify-colors-brand-primary-20: white !important;
  --amplify-colors-brand-primary-10: white !important;
}

.auth-from {
  width: 40%;
}
/* [data-amplify-authenticator] {
  width: 100% !important;
} */

/* [data-amplify-authenticator] [data-amplify-container] {
  margin-top: -100px !important;
  width: 30% !important;
} */

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none !important;
  border: none !important;
}

.amplify-input {
  height: 55px !important;
  background-color: #eeeeee !important;
  border: none !important;
  margin-bottom: 20px !important;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus),
.amplify-field-group__outer-end .amplify-field-group__control:focus {
  background-color: #eeeeee !important;
  border: none !important;
  height: 55px !important;
  margin-bottom: 20px !important;
}

.amplify-button[data-variation="primary"] {
  color: rgb(255, 255, 255) !important;
  border-radius: 9px !important;
  font-weight: 500 !important;
  height: 55px !important;
}

.amplify-button[data-variation="link"] {
  color: black !important;
  box-shadow: none !important;
}

h3.amplify-heading {
  font-size: var(--amplify-components-heading-3-font-size);
  font-weight: var(--amplify-components-heading-3-font-weight);
  margin-bottom: 10px !important;
}
/*******************************************************************/
