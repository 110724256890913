.profile-root{
  padding:0 24px ;
}

.profile-segment{
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: white  ;
  border-bottom: dashed 2px #eee;
  
}

.profile-details-part{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
}
.profile-picture-big{
  width: 128px;
  height: 128px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.profile-picture-big:hover{
  opacity: 60%;
}
.profile-details-column{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.profile-benchmark-part{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.slider-entry{
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50vw;
}